<template>

  <div id="page-user-list">

    <div class="vx-card mb-base p-6" v-show="loaded">
      <div class="flex justify-between flex-wrap items-end mb-5">

        <div>
          <h4 class="mb-2">Messenger Activity</h4>
          <span class="text-gray">
            <feather-icon icon="CalendarIcon" svgClasses="h-4 w-4 hover:text-primary" />
            {{ dateForHumans }}
          </span>
        </div>

        <div>
          <label class="vs-input--label">{{$i18n.t('Date')}}</label>
          <datepicker format="d/MM/yyyy" :value="date" @input="date = $moment($event)"></datepicker>
        </div>

      </div>

      <div class="schedule"  v-if="items.map(item => item.schedules.length).flat(1).reduce((total, item) => { total += item; return total; }, 0) > 0">
        <div class="schedule-header">
          <div class="schedule-title"><h6>Messenger Name</h6></div>
          <div class="schedule-times" :style="`grid-template-columns: repeat(${times.length}, 1fr)`">
            <div class="time" v-for="time in times">{{time}}:00</div>
          </div>
        </div>
        <div class="schedule-content">
          <div class="schedule-item" v-for="(item, i) in items" :key="i">
            <div class="schedule-messenger">{{item.name}}</div>
            <div class="schedule-fill">
              <div @click.prevent="showRequest(schedule.req)" :class="`schedule-fill-item ${colors[i]} text-white`" :style="`width: ${getWidth(schedule)}%; left: ${getLeft(schedule)}%;`" v-for="schedule in item.schedules">
                <marquee behavior="scroll" direction="left" scrolldelay="200">{{schedule.req.tujuan}}</marquee>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else><small>No activity.</small></div>

    </div>

    <div class="vx-card p-6">

      <div class="vx-row">
        <div class="vx-col w-full md:w-6/12">
          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input
            class="
              sm:mr-4
              mr-0
              w-full
              lg:w-6/12
              sm:order-normal
              order-3
              sm:mt-0
              mt-4
            "
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->
        </div>

        <div class="vx-col w-full md:w-6/12">
          <div class="vx-row justify-end">
            <vs-button
              type="border"
              class="mr-5"
              color="primary"
              icon-pack="feather"
              icon="icon-file"
              @click="showExportPopup"
              >Export Data
            </vs-button>

            <vs-button
              color="primary"
              class="mr-4"
              icon-pack="feather"
              icon="icon-plus"
              @click="addItem"
              >Add Item
            </vs-button>
          </div>
        </div>
      </div>

        <!-- AgGrid Table -->
        <ag-grid-vue
          ref="agGridTable"
          :components="components"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="itemsData"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl">
        </ag-grid-vue>

        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full">
            <!-- ITEMS PER PAGE -->
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>

                <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                  <span>25</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                  <span>30</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div class="vx-col sm:w-1/2 w-full">
            <!-- PAGINATION -->
            <vs-pagination
              :total="getTotalPage"
              :max="7"
              v-model="currentPage" />
          </div>
        </div>

    </div>

    <!--  Popup Edit Request  -->
    <vs-popup classContent="popup-example" :title="`${$i18n.t('RequestMessenger')}`" :active.sync="popupRequest">

      <div class="vx-row">
        <div class="vx-col md:w-4/12 mb-3 w-full">
          <label class="vs-input--label">{{$i18n.t('Date')}}</label>
          <datepicker format="d/MM/yyyy" :value="form.date" @input="form.date = $moment($event)" :disabled="form.status == 0 || form.status == 1 || form.status == 2 || form.status == 3 || form.status == 4"></datepicker>
        </div>
        <div class="vx-col md:w-4/12 mb-3 w-full">
          <label class="vs-input--label">{{$i18n.t('TimeOut')}}</label>
          <flat-pickr :config="configdateTimePicker" v-model="form.waktu_keluar" :disabled="form.status == 0 || form.status == 1 || form.status == 3 || form.status == 4" :placeholder="`00:00`" style="width: 100%" />
          <!--          <vs-input type="time" class="w-full mb-3" :disabled="form.status == 0 || form.status == 1 || form.status == 3 || form.status == 4" :label="$i18n.t('TimeOut')" v-model="form.waktu_keluar" />-->
        </div>
        <div class="vx-col md:w-4/12 mb-3 w-full">
          <label class="vs-input--label">{{$i18n.t('TimeCome')}}</label>
          <flat-pickr :config="configdateTimePicker" v-model="form.waktu_datang" :disabled="form.status == 0 || form.status == 3 || form.status == 4" :placeholder="`00:00`" style="width: 100%" />
          <!--          <vs-input type="time" class="w-full mb-3" :disabled="form.status == 0 || form.status == 3 || form.status == 4" :label="$i18n.t('TimeCome')" v-model="form.waktu_datang" />-->
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-8/12 w-full">
          <vs-input class="w-full mb-3" :disabled="[3, 4].some(item => item == form.status)" :label="$i18n.t('PurposeReal')" v-model="form.tujuan" />
        </div>
        <div class="vx-col md:w-4/12 w-full">
          <small class="mb-1">{{ $i18n.t('Priority') }}</small>
          <v-select :clearable="false" v-model="form.priority" :disabled="form.status == 0 || form.status == 1 || form.status == 2 || form.status == 3 || form.status == 4" :reduce="item => item.value" :options="priorities" label="text"></v-select>
        </div>
      </div>

      <div class="mt-3">
        <label for="" class="vs-input--label">{{$i18n.t('Description')}}</label>
        <vs-textarea height="200px" class="w-full mb-5" v-model="form.keterangan" />
      </div>

      <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="addRequest" v-if="!select">
        {{ $i18n.t('Save') }}
      </vs-button>
      <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="updateStatus" v-else>
        {{ $i18n.t('Update') }}
      </vs-button>

    </vs-popup>

    <!--  Popup Edit Status  -->
    <vs-popup classContent="popup-example" :title="`Edit Status & ${$i18n.t('Messenger')}`" :active.sync="popupEditStatus">

      <div class="vx-row mb-5" v-if="select">
        <div class="vx-col md:w-1/2 w-full">
          <small class="mb-1">{{ $i18n.t('Status') }}</small>
          <v-select :clearable="false" v-model="form.status" :reduce="item => item.value" :options="status.filter(s => s.value != 0)" label="text"></v-select>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <small class="mb-1">{{ $i18n.t('Messenger') }}</small>
          <v-select :clearable="false" v-model="form.id_driver" :reduce="item => item.nik" :options="messengers.concat([{nik: 0, name: '-'}])" label="name"></v-select>
        </div>
      </div>

      <div>
        <vs-button color="primary" type="filled" @click="addRequest" v-if="!select">Save</vs-button>
        <vs-button color="primary" type="filled" @click="updateStatus" v-else>Update</vs-button>
      </div>

    </vs-popup>

    <vs-prompt
      @cancel="form.finish = false"
      @accept="finishRequest"
      @close="form.finish = false"
      :is-valid="form.finish"
      :active.sync="popupFinish">
      <div class="con-exemple-prompt">
        <span>Switch and accept to Finish.</span>
        <vs-switch color="primary" class="mt-3" v-model="form.finish">
          <span slot="on">Finish</span>
          <span slot="off">Approved</span>
        </vs-switch>
      </div>
    </vs-prompt>

    <!--  Popup Detail  -->
    <vs-popup classContent="popup-example" :title="`Detail ${$i18n.t('RequestDriver')}`" :active.sync="popupDetail" v-if="select">

      <div class="vx-row">
        <div class="vx-col md:w-8/12 w-full">
          <h6 class="mb-1 text-gray">Originator</h6>
          <p>{{select.originator}}</p>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col md:w-4/12 w-full">
          <h6 class="mb-1 text-gray">Tanggal</h6>
          <p>{{select.date}}</p>
        </div>
        <div class="vx-col md:w-4/12 w-full">
          <h6 class="mb-1 text-gray">Tujuan</h6>
          <p>{{select.tujuan}}</p>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col md:w-4/12 w-full">
          <h6 class="mb-1 text-gray">Waktu Keluar</h6>
          <p>{{select.waktu_keluar}}</p>
        </div>
        <div class="vx-col md:w-4/12 w-full">
          <h6 class="mb-1 text-gray">Waktu Datang</h6>
          <p>{{select.waktu_datang}}</p>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">

        <div class="vx-col md:w-4/12 w-full">
          <h6 class="mb-1">Messenger</h6>
          <p>{{select.messenger ? select.messenger.name : '-'}}</p>
        </div>

      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col md:w-12/12 w-full">
          <h6 class="mb-1">Keterangan</h6>
          <p>{{select.keterangan}}</p>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col md:w-4/12 w-full">
          <h6 class="mb-1">Priority</h6>
          <p>{{priorities.find(priority => select.priority == priority.value).text}}</p>
        </div>
        <div class="vx-col md:w-4/12 w-full">
          <h6 class="mb-1">Status</h6>
          <p>
            <vs-chip :color="status.find(s => select.status == s.value).color">
              {{status.find(s => select.status == s.value).text}}
            </vs-chip>
          </p>
        </div>
      </div>

      <vs-divider></vs-divider>

      <vs-button color="primary" size="small" class="mr-3" icon-pack="feather" icon="icon-edit" @click="popupDetail = false, editItem(select)">
        {{$i18n.t('Edit')}}
      </vs-button>

    </vs-popup>

    <!-- PopUp Export -->
    <div class="centerx">
      <vs-popup
        class="holamundo"
        title="Export Request Messenger"
        :active.sync="popupExport"
      >
        <div class="vx-row">
          <div class="vx-col w-full md:w-6/12">
            <vs-input
              type="date"
              size="small"
              :label="$i18n.t('StartDate')"
              v-model="exportPayload.start_date"
              class="w-full mr-2"
            ></vs-input>
          </div>
          <div class="vx-col w-full md:w-6/12">
            <vs-input
              type="date"
              size="small"
              :label="$i18n.t('EndDate')"
              v-model="exportPayload.end_date"
              class="w-full mr-2"
            ></vs-input>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <vs-button
              :disabled="disableExportBtn"
              class="w-full"
              icon-pack="feather"
              icon="icon-file"
              @click="exportData"
            >
              Export Requests Messenger
            </vs-button>
          </div>
        </div>
      </vs-popup>
    </div>

  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import Datepicker from "vuejs-datepicker";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import recipe from '@/recipe';

// Import configuration
import vehicles from "@/config/vehicleConfig.js";

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererPriority from "./cell-renderer/CellRendererPriority"
import CellRendererLink from "./cell-renderer/CellRendererLink"
import CellRendererCar from "./cell-renderer/CellRendererCar";


export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,
    flatPickr,

    // Cell Renderer
    CellRendererStatus,
    CellRendererActions,
    CellRendererPriority,
    CellRendererLink,
    CellRendererCar
  },
  data () {
    return {
      loaded: false,
      date: '',
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        time_24hr: true,
        defaultHour: 8,
        minuteIncrement: 30
      },
      colors: ['bg-primary', 'bg-primary', 'bg-warning'],
      popupEditStatus: false,
      popupDetail: false,
      popupFinish: false,
      popupRequest: false,
      popupExport: false,
      exportPayload: {
        start_date: '',
        end_date: ''
      },
      form: {
        priority: 1,
        id_driver: 0,
        jenis: ""
      },
      times: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
      selected: [],
      select: false,
      priorities: [
        {text: "Urgent", value:1},
        {text: "Major", value:2},
        {text: "Minor", value:3},
      ],
      jenis: vehicles.filter(vehicle => vehicle.available),
      messengers: [],

      searchQuery: '',

      // AgGrid
      gridApi: null,
      getTotalPage: 0,
      gridOptions: {
        pagination: true,
        onPaginationChanged: this.onPaginationChanged,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'Messenger',
          field: 'messenger.name',
          filter: true,
          cellRendererFramework: 'CellRendererLink',
        },
        {
          headerName: 'Originator',
          field: 'originator',
          filter: true,
          cellClass: 'text-center',
        },
        {
          headerName: 'Tujuan',
          field: 'tujuan',
          filter: true,
          cellClass: 'text-center',
        },
        {
          headerName: 'Priority',
          field: 'priority',
          filter: true,
          cellClass: 'text-center',
          cellRendererFramework: 'CellRendererPriority',
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
          cellClass: 'text-center',
          cellRendererFramework: 'CellRendererStatus',
          cellRendererParams: {
            editStatus: this.editStatus.bind(this),
            showRequest: this.showRequest.bind(this)
          }
        },
        {
          headerName: 'Action',
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            editItem: this.editItem.bind(this),
            showRequest: this.showRequest.bind(this)
          }
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererStatus,
        CellRendererActions,
        CellRendererPriority,
        CellRendererLink,
        CellRendererCar
      }
    }
  },
  watch: {
    date () {
      this.loadRequest(this.date);
    }
  },
  computed: {
    dateForHumans () {
      let date = new Date(this.date);
      if (this.date == '')
        date = new Date();
      return moment(date).format('ddd, DD-MM-yyyy');
    },
    status () {
      let stat = [
        {text: "Finish", value:0, color: 'primary'},
        {text: "Approved", value:1, color: 'success'},
        {text: "Waiting Approval", value:2, color: 'warning'},
        {text: "Reject", value:3, color: 'orange'},
        {text: "Cancel", value:4, color: 'danger'},
      ];

      return stat;
    },
    itemsData () {
      return this.$store.state.request.items
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },

    items () {
      let names = this.$store.state.request.messengers.map(messenger => messenger.name);

      let messengers = [];
      names.forEach(name => {
        let schedules = [];

        this.$store.state.request.items.forEach(req => {
          if (req.messenger && req.status == 1) {
            if (req.messenger.name == name) {
              let waktu_keluar = req.waktu_keluar,
                waktu_datang = req.waktu_datang;

              schedules.push({
                waktu_keluar,
                waktu_datang,
                req,
                car: this.jenis.find(j => j.id == req.jenis).name
              })
            }
          }
        });

        messengers.push({
          name,
          schedules
        })
      });

      return messengers;
    },

    disableExportBtn() {
      if (
        this.exportPayload.start_date == "" ||
        this.exportPayload.end_date == ""
      ) {
        return true;
      }

      return false;
    }
  },
  methods: {

    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },

    onPaginationChanged(){
      return this.getTotalPage = this.gridOptions.api.paginationGetTotalPages()
    },

    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },

    getOrderStatusColor (status) {
      if (status === 2)   return 'warning'
      if (status === 1) return 'success'
      if (status === 0) return 'primary'
      if (status === 3)  return 'danger'
      return 'danger'
    },
    loadRequest (date = false) {
      let params = {
        type: 'messenger',
        date
      };

      this.$store.dispatch('request/fetchItems', { params }).then(resp => {
        this.date = resp.data.values.date;
      }).catch(err => {
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Opps something error',
          text: err.response.data.message,
          color:'danger'})
      })
      .finally(() => {
        this.gridApi = this.gridOptions.api;
        this.gridApi.sizeColumnsToFit();
        this.onLoaded();
      });
    },
    loadMessengers () {
      this.$store.dispatch("request/fetchMessengers").then(response => {
        this.messengers = response.data.values;
      });
    },
    addItem () {
      this.form = {
        priority: 1,
        id_driver: 0,
        jenis: ""
      };
      this.popupRequest = true;
      this.select = false;
      this.form.date = this.date;
    },
    editItem (request) {
      this.popupRequest = true;

      this.select = request;
      this.form = this.select;
    },
    editStatus (request) {
      if (request.status == 1) {
        this.popupFinish = true;
      } else {
        this.popupEditStatus = true;
      }

      this.select = request;
      this.form = this.select;
    },
    showRequest (request) {
      this.select = request;
      this.popupDetail = true;
    },
    addRequest () {
      let payload = {
        body: this.form
      };
      payload.body.originator = this.$store.state.AppActiveUser.employee.name;
      payload.body.transport = "Motor";

      this.$store.dispatch("request/storeItem", payload).then((response) => {
        this.popupRequest = false;
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.date = payload.body.date;
          this.loadRequest(payload.body.date);
        });
      }).catch(err => {
        console.log(err);
        if (!err) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'})
      });
    },
    updateStatus () {
      let payload = {
        body: this.form
      };

      payload.body._method = 'PUT';
      payload.body.originator = this.$store.state.AppActiveUser.employee.name;
      payload.id = this.select.id;

      this.$store.dispatch("request/updateItem", payload).then((response) => {
        this.popupEditStatus = false;
        this.popupRequest = false;
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.date = payload.body.date;
          this.loadRequest(payload.body.date);
        });
      }).catch(err => {
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Opps something error',
          text: err.response.data.message,
          color:'danger'})
      });
    },

    // Show Export Popup
    showExportPopup() {
      this.popupExport = true;
      this.exportPayload.start_date = "";
      this.exportPayload.end_date = "";
    },

    exportData() {
      window.open(`${recipe}/api/v1/requests/export/${this.exportPayload.start_date}/${this.exportPayload.end_date}/excel?type=messenger`, '_blank')

      this.popupExport = false
    },

    finishRequest () {
      let payload = {
        body: this.form
      };

      payload.body._method = 'PUT';
      payload.body.originator = this.$store.state.AppActiveUser.employee.name;
      payload.finish = true;
      payload.id = this.select.id;

      this.$store.dispatch("request/updateItem", payload).then((response) => {
        this.popupFinish = false;
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          this.date = payload.body.date;
          this.loadRequest(payload.body.date);
        });
      }).catch(err => {
        if (!err.response) {
          // network error
          err.response = {
            data: {
              message: 'Server error'
            }
          };
        }

        this.$vs.notify({
          title:'Opps something error',
          text: err.response.data.message,
          color:'danger'})
      });
    },

    getLeft (item) {
      let startMinutes = 8 * 60;
      let startTimeMinutes = new Date(`2020-12-12 ${item.waktu_keluar}`).getMinutes() + new Date(`2020-12-12 ${item.waktu_keluar}`).getHours() * 60;


      let diff = startTimeMinutes - startMinutes;
      let all = this.times.length * 60;

      let result = (diff/all) * 100;

      return result;
    },

    getWidth (item) {
      let startTimeMinutes = new Date(`2020-12-12 ${item.waktu_keluar}`).getMinutes() + new Date(`2020-12-12 ${item.waktu_keluar}`).getHours() * 60;
      let endTimeMinutes = new Date(`2020-12-12 ${item.waktu_datang}`).getMinutes() + new Date(`2020-12-12 ${item.waktu_datang}`).getHours() * 60;

      let diff = endTimeMinutes - startTimeMinutes;
      let all = this.times.length * 60;

      let result = (diff/all) * 100;

      return result;
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  mounted () {

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.loading();
    this.loadMessengers();
    this.loadRequest();

  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.schedule {
  width: 100%;
}
.schedule-title {
  padding: 8px;
}
.schedule-header {
  display: grid;
  grid-template-columns: 2fr 8fr;
  border-bottom: 1px solid #808080;
  border-top: 1px solid #808080;
}
.schedule-times {
  display: grid;
}
.time {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: start;
}
.schedule-messenger {
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
}
.schedule-item {
  display: grid;
  grid-template-columns: 2fr 8fr;
  border-bottom: 1px solid #808080;
}
.schedule-fill {
  position: relative;
  overflow: hidden;
}
.schedule-fill-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px;
  padding: 3px 0px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: .4s;
  line-height: 1;
}

.vs-chip-success {
  background: rgba(var(--vs-success),.15);
  color: rgba(var(--vs-success),1) !important;
  font-weight: 500;
}
.vs-chip-primary {
  background: rgba(var(--vs-primary),.15);
  color: rgba(var(--vs-primary),1) !important;
  font-weight: 500;
}
.vs-chip-warning {
  background: rgba(var(--vs-warning),.15);
  color: rgba(var(--vs-warning),1) !important;
  font-weight: 500;
}
.vs-chip-danger {
  background: rgba(var(--vs-danger),.15);
  color: rgba(var(--vs-danger),1) !important;
  font-weight: 500;
}
.vs-popup--content {
  overflow: visible;
}
.vdp-datepicker__calendar{
  right:0;
}
</style>
